<template>
  <div>
    <div class="col s12 m5 l4">
      <ul class="collapsible">
        <li v-for="(b, index) in petsa" :key=index>
          <div class="inline-icon collapsible-header left-align"
          @click="setPlace(b.map.lat, b.map.lng)">
            <i class="material-icons">keyboard_arrow_right</i>
            <h2 class="name-h2">{{b.name}}</h2>
            <span v-if="b.isNew" class="new badge truncate" data-badge-caption="Nueva Sucursal"></span>
            <span v-if="b.soon" class="new badge truncate" data-badge-caption="Próximamente"></span>
          </div>
          <div class="collapsible-body">
            <div class="row">
              <div class="col s12 address">
                <div v-if="b.alt" class="seo-show">
                  <h3 v-for="(a, index) in b.alt" :key="index">{{ a }}</h3>
                </div>
                <p>
                  Productos Eléctricos de Tijuana, S.A. de C.V.<br>
                  {{b.address}} <b>{{b.city}}</b>, C.P. {{b.zipCode}}<br>
                  <a class="link_maps" target="blank" :href="'https://www.google.com/maps?q=' + b.map.lat + ',' + b.map.lng">
                    Como llegar
                  </a>
                </p>
              </div>
              <div class="col s12 tel" v-if="b.phones">
                <span>Tel.</span><br>
                <span v-for="(p, index) in b.phones" :key="index">{{p}}<br></span>
              </div>
              <br>
              <div class="col s12">
                <img :src="b.logo"
                class="logo" alt="logo">
              </div>
            </div>
          </div>
        </li>
        <li v-for="(b, index) in traeesa" :key="index+'traeesa'">
          <div class="collapsible-header left-align"
          @click="setPlace(b.map.lat, b.map.lng)">
            <i class="material-icons">keyboard_arrow_right</i>
            <h2 class="name-h2">{{b.name}}</h2>
            <span v-if="b.isNew" class="new badge truncate" data-badge-caption="Nueva Sucursal"></span>
            <span v-if="b.soon" class="new badge truncate" data-badge-caption="Próximamente"></span>
          </div>
          <div class="collapsible-body">
            <div class="row">
              <div v-if="b.alt" class="seo-show">
                <h3 v-for="(a, index) in b.alt" :key="index">{{ a }}</h3>
              </div>
              <div class="col s12 address">
                <p>
                  Transformadores y Equipos Eléctricos de B.C.S. S.A. de C.V.<br>
                  {{b.address}} <b>{{b.city}}</b>, C.P. {{b.zipCode}}<br>
                  <a class="link_maps" target="blank" :href="'https://www.google.com/maps?q=' + b.map.lat + ',' + b.map.lng">
                    Como llegar
                  </a>
                </p>
              </div>
              <div class="col s12 tel" v-if="b.phones">
                <span>Tel.</span><br>
                <span v-for="(p, index) in b.phones" :key="index">{{p}}<br></span>
              </div>
              <br>
              <div class="col s12">
                <img :src="b.logo"
                class="logo" alt="logo">
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col s12 m7 l8">
      <GmapMap :center='center' :zoom="zoom"
        style='width:100%; height: 600px;'>
          <GmapMarker
            v-for="(m, index) in markers" :key=index
            :position="m"
            :icon="m.icon"
            @click="setPlace(m.lat, m.lng)"/>
      </GmapMap>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'

export default {
  name: 'GoogleMap',
  data() {
    return {
      petsa:[],
      traeesa:[],
      isLoading:true,
      selectedPlace: null,
      center: {
        lat: 27.4254,
        lng: -108.2258
      },
      markers: [],
      zoom: 5,
    }
  },
  mounted() {
    this.initComponents();
    this.get_branches();
    this.centrar();
  },
  methods: {
    // addMarker() {
    //   if (this.currentPlace) {
    //     const marker = {
    //       lat: this.currentPlace.geometry.location.lat(),
    //       lng: this.currentPlace.geometry.location.lng(),
    //     };
    //     this.markers.push({ position: marker });
    //     this.places.push(this.currentPlace);
    //     this.center = marker;
    //     this.currentPlace = null;
    //   }
    // },
    // geolocate: function() {
    //   navigator.geolocation.getCurrentPosition(position => {
    //     this.center = {
    //       lat: position.coords.latitude,
    //       lng: position.coords.longitude,
    //     };
    //   });
    // },
    initComponents(){
      var elemsCollapsible = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elemsCollapsible, {});
    },
    get_branches () {
      this.petsa = [
        {
          name:    'Oficinas Centrales',
          alt:     [],
          address: 'Calle A No.10 Fracc. Santa Elena',
          city:    'Tijuana, B.C.',
          zipCode: 22114,
          phones:  ['(664) 687 9000'],
          logo:    require('../../assets/Logos/grupo.png'),
          map:     { lat: 32.49745771303317, lng: -116.95274924815493 },
          macroadminUrl: 'http://pettij.dyndns.org:8081',
          isNew:   false,
        },
        {
          name:    'PETSA Tijuana',
          alt:     ['PETSA Matriz'],
          address: 'Calle A No.10 Fracc. Santa Elena',
          city:    'Tijuana, B.C.',
          zipCode: 22114,
          //phones:  ['(664) 626 1199', '(664) 608 6051', '(664) 686 8601'],
          phones:  ['(664) 686 8601', '(664) 686 0562', '(664) 608 6030'],
          logo:    require('../../assets/Logos/petsa.png'),
          map:     { lat: 32.49727051446119, lng: -116.95282971442538 },
          macroadminUrl: 'http://pettij.dyndns.org:8081',
          isNew:   false,
        },
        {
          name:    'PETSA Express Tijuana Vía Rápida',
          alt:     ['PETSA Vía Rápida'],
          address: 'San Ignacio No.14475 Los Santos',
          city:    'Tijuana, B.C.',
          zipCode: 22104,
          phones:  ['(664) 621 6491', '(664) 621 6427', '(664) 621 6359'],
          logo:    require('../../assets/Logos/petsaexpress.png'),
          map:     { lat: 32.51134296607451, lng: -116.96672347332431 },
          macroadminUrl: 'http://petexpress.dyndns.org:8080',
          isNew:   false,
        },
        {
          name:    'PETSA Express Tijuana Cedros',
          alt:     ['PETSA Cedros', 'PETSA Santa Fe' ],
          address: 'Blvd. Paseo Banderas #4410, Col. Urbi Quinta del Cedro',
          city:    'Tijuana, B.C.',
          zipCode: 22564,
          phones:  ['(664) 976 9131'],
          logo:    require('../../assets/Logos/petsaexpress.png'),
          map:     { lat: 32.439075055881915, lng: -117.06226013557671 },
          isNew:   false,
          soon:    false
        },
        {
          name:    'PETSA Campos',
          alt:     ['PETSA Insurgentes', 'PETSA Clinica 1', 'PETSA Capistrano'],
          address: 'Avenida Campos #30, Col. Cerro Colorado',
          city:    'Tijuana, B.C.',
          zipCode: 22223,
          phones:  ['(664) 625 1511', '(664) 625 1463'],
          logo:    require('../../assets/Logos/petsa-elec-plo.png'),
          map:     { lat: 32.47578768431832, lng: -116.9234459031068 },
          macroadminUrl: 'http://petexpress.dyndns.org:8080',
          isNew:   false,
          soon:    false
        },
        {
          name:    'PETSA Otay',
          address: 'Blvd. Alberto Limón Padilla #19233, Ciudad Industrial',
          city:    'Tijuana, B.C.',
          zipCode: 22444,
          phones:  ['(664) 904 3586'],
          logo:    require('../../assets/Logos/petsa-elec-plo.png'),
          map:     { lat: 32.538027, lng: -116.916007 },
          macroadminUrl: 'http://petexpress.dyndns.org:8080',
          isNew:   false,
        },
        {
          name:    'PETSA Express Rosarito',
          alt:     ['PETSA Rosarito'],
          address: 'Carretera Libre Tijuana-Ensenada Km 19, Rancho la Esperanza',
          city:    'Playas de Rosarito, B.C.',
          zipCode: 22710,
          phones:  ['(661) 613 4402', '(661) 100 1599'],
          logo:    require('../../assets/Logos/petsaexpress.png'),
          map:     { lat: 32.40632712788079, lng: -117.04908833545376 },
          macroadminUrl: 'http://petexp2.dyndns.org:8080',
          isNew:   false,
        },
        {
          name:    'PETSA Mexicali Lázaro Cárdenas',
          alt:     ['PETSA Mexicali'],
          address: 'Blvd. Lázaro Cárdenas # 1475 Col. Ampliación del Rastro',
          city:    'Mexicali, B.C.',
          zipCode: 21090,
          phones:  ['(686) 557 3412', '(686) 557 2830'],
          logo:    require('../../assets/Logos/petsa.png'),
          map:     { lat:  32.624100, lng: -115.465976 },
          macroadminUrl: 'http://petmex.dyndns.org:8080',
          isNew:   false,
        },
        {
          name:    'PETSA Express Mexicali',
          alt:     ['PETSA Mexicali'],
          address: 'Blvd. Lázaro Cárdenas # 1393 Col. Ampliación del Rastro',
          city:    'Mexicali, B.C.',
          zipCode: 21090,
          phones:  ['(686) 555 4790', '(686) 555 5956', '(686) 582 3272'],
          logo:    require('../../assets/Logos/petsaexpress.png'),
          map:     { lat: 32.62420460930328, lng: -115.4655858246498 },
          macroadminUrl: 'http://petexpmex.dyndns.org:8080',
          isNew:   false,
        },
        {
          name:    'PETSA Mexicali Gómez Morín',
          alt:     ['PETSA Mexicali'],
          address: 'Calz Manuel Gómez Morín #570, Parcela 44',
          city:    'Mexicali, B.C.',
          zipCode: 21390,
          phones:  ['(686) 842 6240',],
          logo:    require('../../assets/Logos/petsa-elec-plo.png'),
          map:     { lat:  32.611942, lng: -115.433978 },
          macroadminUrl: 'http://petmex.dyndns.org:8080',
          isNew:   false,
        },
        {
          name:    'PETSA Express Tecate',
          alt:     ['PETSA Tecate'],
          address: 'Blvd. Encinos 812, Los Encinos',
          city:    'Tecate, B.C.',
          zipCode: 21460,
          phones:  ['(665) 521 4511', '(665) 655 8989'],
          logo:    require('../../assets/Logos/petsaexpress.png'),
          map:     { lat: 32.56429514136384, lng: -116.63799981533334 },
          macroadminUrl: 'http://pettkt.dyndns.org:8080',
          isNew:   false,
        },
        {
          name:    'PETSA Ensenada',
          address: 'Av. López Mateos # 2110, Zona Centro',
          city:    'Ensenada, B.C.',
          zipCode: 22810,
          phones:  ['(646) 176 1840', '(646) 177 3533'],
          logo:    require('../../assets/Logos/petsa.png'),
          photo:   'img/petsa/fachadas/12.mini.jpg',
          map:     { lat: 31.85614835981016, lng: -116.6084119019176 },
          macroadminUrl: 'http://petens.dyndns.org:8000',
          isNew:   false,
        },
        {
          name:    'PETSA Express Ensenada',
          address: 'Calle Huerta # 31, Vista Hermosa',
          city:    'Ensenada, B.C.',
          zipCode: 22895,
          phones:  ['(646) 978 0100', '(646) 596 1168', '(646) 172 9423'],
          logo:    require('../../assets/Logos/petsaexpress.png'),
          photo:   'img/petsa/fachadas/12.mini.jpg',
          map:     { lat: 31.81399290850976, lng: -116.59611665226089 },
          isNew:   false,
        },
        {
          name:    'PETSA Express San Quintín',
          address: 'Carretera Transpeninsular #1040-F, Nuevo Mexicali.',
          city:    'San Quintín, B.C.',
          zipCode: 22930,
          phones:  ['(616) 596 8801'],
          logo:    require('../../assets/Logos/petsaexpress.png'),
          photo:   'img/petsa/fachadas/12.mini.jpg',
          map:     { lat: 30.546314529350642, lng: -115.93828043939799 },
          isNew:   true,
          soon:    false
        },];
      this.traeesa = [{
          name:    'TRAEESA San José del Cabo',
          alt:     ['TRAEESA Cabos'],
          address: 'Av. De La Floresta s/n',
          city:    'San José del Cabo, B.C.S',
          zipCode: 23400,
          phones:  ['(624) 130 3651', '(624) 146 2777', '(624) 146 2909'],
          logo:    require('../../assets/Logos/traeesa.png'),
          map:     { lat: 23.136771269714565, lng: -109.70941355175371 },
          macroadminUrl: 'http://petcabos.dyndns.org:8081',
          isNew:   false,
        },
        {
          name:    'TRAEESA Express La Paz',
          alt:     ['TRAEESA La Paz'],
          address: 'Mariano Abasolo Lote 12 Manzana 966 s/n Barrio el Manglito',
          city:    'La Paz, Baja California Sur',
          zipCode: 23060,
          phones:  ['(612) 128 1219', '(612) 128 1131', '(612) 128 0807'],
          logo:    require('../../assets/Logos/traeesaexpress.png'),
          map:     { lat: 24.147354586927406, lng: -110.32990828636494 },
          macroadminUrl: 'http://expresslapaz.dyndns.org:8000',
          isNew:   false,
        },];
      this.markers = [];

      this.petsa.map(i => {
        this.markers.push({
          lat: parseFloat(i.map.lat),
          lng: parseFloat(i.map.lng),
          icon: require('../../assets/img/map_marker.png')
        });
      });
      this.traeesa.map(i => {
        this.markers.push({
          lat: parseFloat(i.map.lat),
          lng: parseFloat(i.map.lng),
          icon: require('../../assets/img/map_marker.png')
        });
      });
    },
    setPlace(latitude, longitude) {
      if(this.selectedPlace != null){
        this.markers[this.selectedPlace].icon = require('../../assets/img/map_marker.png');
      }

      let center = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
      };

      const indexPlace = this.markers.findIndex(marker => marker.lat === center.lat
        && marker.lng === center.lng);
      this.selectedPlace = indexPlace;

      if(this.center.lat == center.lat && this.center.lng == center.lng){
        this.centrar();
      }else{
        this.zoom = 15;
        this.center = center;
        // this.markers[indexPlace].icon = require('../../assets/img/map_marker_select.png');
        this.markers[indexPlace].icon = require('../../assets/img/map_marker.png');
      }
    },
    centrar(){
      let center = {
        lat: 27.4254,
        lng: -108.2258
      };
      if(document.body.clientWidth <= 992){
        center = {
          lat: 27.4254,
          lng: -114.2258
        };
      }
      this.center = center;
      this.zoom = 5;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.collapsible-header {
  font-family: 'Avenir-Regular';
  color: #000;
  padding: 5px !important;
  font-size: 16px !important;
  font-weight: 600;
}
.collapsible-header i{
  font-size: 20px !important;
}
.collapsible .active .collapsible-header,
.collapsible-header:hover{
  color: #fff;
  background: #DB272F;
  font-weight: 600;
}
.collapsible-header:hover{
  font-weight: 800 !important;
}
.collapsible-body {
  padding: 5px !important;
}
p{
  font-size: 1rem;
}
.collapsible-header i {
  margin-right: 0 !important;
}

.tel{
  font-family: 'Avenir-Regular';
  color: #000;
}
.logo {
  max-height: 100px;
  bottom: 10px;
  right: 5px;
  margin-top: 10px;
}
span.badge.new {
  background: #DB272F !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 800 !important;
  border: 1px solid #111111;
  border-radius: 8px;
}
.link_maps{
  font-size: 17px;
  color: #DB272F;
  cursor: pointer;
}
.link_maps:hover{
  text-decoration: underline;
}
.name-h2{
  font-family: 'Avenir-Regular' !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 0 !important;
  padding: 5px !important;
}
</style>