<template>
  <div id="div-banner-magazine" v-if="date <= validity">
    <div class="row contenedor hide-on-med-and-down">
      <div class="col s12 padding-0">
        <router-link to="/magazine" id="magazine_page">
          <img class="responsive-img" alt="minibanner"
            v-lazy="require('../../assets/Revista/BANNER_REVISTA-01.jpg')"/>
        </router-link>
      </div>
    </div>
    <div class="width-100 hide-on-large-only">
      <router-link to="/magazine" id="magazine_page">
        <img class="responsive-img" alt="minibanner"
          v-lazy="require('../../assets/Revista/BANNER_REVISTA_MOVIL-01.jpg')"/>
      </router-link>
    </div>
  </div>
</template>

<script>
// import M from 'materialize-css'
export default {
  name: 'Minibanner',
  data(){
    return{
      date:new Date(),
      validity: new Date('Oct 1, 2024')
    }
  },
  mounted() {
  },
  methods:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.padding-0{
  padding: 0 !important;
}
.width-100{
  width: 100%;
}
</style>