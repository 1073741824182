import Home from "@/views/Home.vue";
import Products from "@/views/Products.vue";
import Services from "@/views/Services.vue";
import AboutUs from "@/views/AboutUs.vue";
import BranchLocations from "@/views/BranchLocations.vue";
import Product from "@/views/Product.vue";
import Cart from "@/views/ShoppingCart.vue";
import Magazine from "@/views/Magazine.vue";
import MagazineTRAEESA from "@/views/MagazineTRAEESA.vue";
import Employees from "@/views/Employees.vue";
import DownloadInvoices from "@/views/DownloadInvoices.vue";
import Whatsapp from "@/views/Whatsapp.vue";
import NotFound from "@/views/NotFound.vue";

let date = new Date();
const validity = new Date('Oct 1, 2024');
const validity_traeesa = new Date('Nov 1, 2024');
let show_offers = true;
let show_offers_traeesa = false;

if (localStorage.getItem("selectedBranch")){
  let sb = JSON.parse(localStorage.getItem("selectedBranch"));
  show_offers = sb.show_offers;
  show_offers_traeesa = sb.show_offers_traeesa;
}

let routesApp = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/products",
    name: "products",
    component: Products
  },
  {
    path: "/product/:id",
    name: "product",
    component: Product
  },
  {
    path: "/services",
    name: "services",
    component: Services
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: AboutUs
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart
  },
  {
    path: "/branchLocations",
    name: "branchLocations",
    component: BranchLocations
  },
  {
    path: "/downloadInvoices/:id",
    name: "downloadInvoices",
    component: DownloadInvoices
  },
  {
    path: "/empleados",
    name: "empleados",
    component: Employees
  },
  {
    path: "/whatsapp",
    name: "Whatsapp",
    component: Whatsapp
  },
];

if(date <= validity && show_offers){
  routesApp.push({
    path: "/magazine",
    name: "magazine",
    component: Magazine
  });
  routesApp.push({
    path: "/magazineTRAEESA",
    name: "magazineTRAEESA",
    component: Home
  });
}
else if(date <= validity_traeesa && show_offers_traeesa){
  routesApp.push({
    path: "/magazineTRAEESA",
    name: "magazineTRAEESA",
    component: MagazineTRAEESA
  });
  routesApp.push({
    path: "/magazine",
    name: "magazine",
    component: Home
  });
}
else{
  routesApp.push({
    path: "/magazine",
    name: "magazine",
    component: Home
  });
  routesApp.push({
    path: "/magazineTRAEESA",
    name: "magazineTRAEESA",
    component: Home
  });
}
routesApp.push({ path: '/:pathMatch(.*)*', component: NotFound });

export default routesApp;